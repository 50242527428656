import { DataTable } from "@/components/tables/data-table";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { RelativeDate } from "@/components/ui/relative-date";
import { LinkButton } from "@/components/buttons/link-button";
import { DataTableLink } from "@/components/ui/data-table-link";
import {
  MutationActionsMenu,
  MutationCloneAction,
  MutationDeleteAction,
  MutationEditAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { BookCheckIcon, BookDashedIcon, FileInputIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@/components/icons/spinner";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { useState } from "react";
import {
  QRPreview,
  TenantInspectionFormsQRCodeBuilder,
} from "./tenant-inspection-forms-qr-code-builder";

export function TenantSettingsInspectionFormsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const navigate = useNavigate();
  const [isQRDialogOpen, setIsQRDialogOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);

  const {
    data = [],
    isLoading,
    error,
  } = trpc.tenantFormTemplatesList.useQuery({
    types: ["INSPECTION"],
    includeUnpublished: true,
    tenantId,
  });

  const { mutate: deleteMutation } = trpc.tenantFormTemplatesDeleteById.useMutation();

  const {
    mutate: copyMutation,
    isLoading: copyIsLoading,
    variables: copyVariables,
  } = trpc.tenantFormTemplatesCopy.useMutation({
    onSuccess: (data) => {
      navigate(`./${data.id}/edit`);
    },
  });

  return (
    <Page isLoading={isLoading}>
      <PageToolbar>
        <PageTitle>{t("form_title_inspection")}</PageTitle>
        <PageActions>
          <LinkButton to={"./create"} className="cursor-pointer justify-start">
            <FileInputIcon className="size-4" />
            {t("form_create")}
          </LinkButton>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <DataTable
          columnsStorageKey="tenant-inspection-forms-page"
          isLoading={isLoading}
          error={error}
          data={data}
          columns={[
            {
              id: "name",
              title: t("name"),
              render(row) {
                return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
              },
            },
            {
              id: "published",
              title: t("published"),
              render(row) {
                if (row.published) {
                  return (
                    <div className="flex items-center gap-x-1">
                      <BookCheckIcon className="size-4" />
                      {t("published")}
                    </div>
                  );
                }
                return (
                  <div className="flex items-center gap-x-1">
                    <BookDashedIcon className="size-4" />
                    {t("draft")}
                  </div>
                );
              },
            },
            {
              id: "updatedAt",
              title: t("updated_at"),
              render: (row) => <RelativeDate date={row.updatedAt} />,
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
            {
              id: "id",
              title: t("qr_code"),
              enableSorting: false,
              render(row) {
                if (row.published) {
                  return (
                    <QRPreview
                      formTemplateId={row.id}
                      formName={row.name}
                      onClick={() => {
                        setIsQRDialogOpen(true);
                        setSelectedFormId(row.id);
                      }}
                    />
                  );
                }
                return t("not_published");
              },
            },
            {
              id: "id",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render(row) {
                if (copyIsLoading && copyVariables?.copiedFromId === row.id) {
                  return <Spinner />;
                }
                return (
                  <MutationActionsMenu data-testid={row.name}>
                    <MutationCloneAction
                      onClick={() => {
                        copyMutation({ copiedFromId: row.id, tenantId });
                      }}
                    />
                    <MutationEditAction to={`./${row.id}/edit`} />
                    <MutationDeleteAction
                      name={row.name}
                      onConfirmDelete={() => deleteMutation({ id: row.id, tenantId })}
                    />
                  </MutationActionsMenu>
                );
              },
            },
          ]}
          searchColumn="name"
          defaultSortColumn="createdAt"
          defaultSortOrder="desc"
        />
      </PageContent>
      {selectedFormId && (
        <TenantInspectionFormsQRCodeBuilder
          formTemplateId={selectedFormId}
          isOpen={isQRDialogOpen}
          onClose={() => {
            setIsQRDialogOpen(false);
          }}
        />
      )}
    </Page>
  );
}
