import { UnsavedChangesBlocker } from "@/components/dialogs/unsaved-changes-blocker";
import { FormBuilder } from "@/components/form-builders/form-builder";
import { Page, PageTitle, PageToolbar, PageContent } from "@/components/layouts/page";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { trpc } from "@/lib/providers/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { formTemplatesCommonUpdateSchemaInput } from "@timp/server/src/schemas/form-templates-common.schema";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { LinkButton } from "@/components/buttons/link-button";
import { FeatureFlagInput } from "@/components/inputs/feature-flag-input";
import type { z } from "zod";
import { SelectMultipleTenantUsers } from "@/components/selects/select-multiple-tenant-users";

type InspectionTemplate = Extract<
  z.infer<typeof formTemplatesCommonUpdateSchemaInput>,
  { type: "INSPECTION" }
>;

export function TenantSettingsInspectionFormsEditPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const tenantId = useTenantIdSafe();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isminimum14DayRollingUsageEnabled, setIsminimum14DayRollingUsageEnabled] = useState(true);

  const { data, isLoading } = trpc.tenantFormTemplatesById.useQuery({
    id: id!,
    tenantId,
  });

  useEffect(() => {
    setIsminimum14DayRollingUsageEnabled(typeof data?.minimum14DayRollingUsage === "number");
  }, [data?.minimum14DayRollingUsage]);

  const updateMutation = trpc.tenantFormTemplatesUpdateById.useMutation({
    onSuccess() {
      form.reset(form.getValues());
      navigate("../");
    },
  });

  const form = useForm<InspectionTemplate>({
    mode: "onChange",
    resolver: zodResolver(formTemplatesCommonUpdateSchemaInput),
    values: data as InspectionTemplate,
  });

  const hasUnsavedChanges = !!form.formState.dirtyFields.formContent;

  return (
    <Page isLoading={isLoading}>
      <PageToolbar>
        <PageTitle backLink>{t("edit_inspection_form")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <div className={cn("space-y-6")}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("form_name")}</FormLabel>
                  <FormControl>
                    <div className="flex items-center gap-x-2">
                      <Input {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="formContent"
              render={() => (
                <FormItem>
                  <FormLabel required={true}>{t("form_content")}</FormLabel>
                  <FormControl>
                    <div className="flex items-center gap-x-2">
                      <Button
                        variant="outline"
                        type="button"
                        onClick={() => {
                          form.trigger("formContent");
                          setDialogOpen(true);
                        }}
                      >
                        {t("edit_form")}
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="minimum14DayRollingUsage"
              render={({ field }) => (
                <FormItem>
                  <div className="mb-2 flex items-center gap-x-2">
                    <FormControl>
                      <Checkbox
                        checked={isminimum14DayRollingUsageEnabled}
                        onCheckedChange={(checked) => {
                          setIsminimum14DayRollingUsageEnabled(checked === true);
                          field.onChange(null);
                        }}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("minimum_rolling_inspections")}</FormLabel>
                  </div>
                  {isminimum14DayRollingUsageEnabled && (
                    <FormControl>
                      <Input
                        ref={field.ref}
                        type="number"
                        value={field.value === null ? "" : field.value}
                        onChange={(ev) => {
                          field.onChange(Number.parseInt(ev.target.value, 10) ?? null);
                        }}
                      />
                    </FormControl>
                  )}
                  <FormDescription>{t("minimum_rolling_inspections_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="published"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center gap-x-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        name={field.name}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("published")}</FormLabel>
                  </div>
                  <FormDescription>{t("form_published_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notifyUsersOnDeviation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("notify_users_on_deviation")}</FormLabel>
                  <FormControl>
                    <SelectMultipleTenantUsers
                      collapseAt={5}
                      values={field.value?.map((id) => ({ id })) ?? []}
                      onChange={(v) => field.onChange(v.map((v) => v.id))}
                    />
                  </FormControl>
                  <FormDescription>{t("notify_users_on_deviation_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableSuppliers"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("supplier")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableSuppliers"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("suppliers_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableDepartments"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("departments")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableDepartments"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("departments_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableProjects"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("projects")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableProjects"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("projects_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableLocations"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("locations")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableLocations"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("locations_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableEquipment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("equipment")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableEquipment"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("equipment_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button
                isLoading={updateMutation.isLoading}
                type="button"
                onClick={() =>
                  form.handleSubmit(
                    (formData) => {
                      updateMutation.mutate({ ...formData, id: id!, tenantId });
                    },
                    (errors) => {
                      if (Object.keys(errors.formContent ?? {}).length > 0) {
                        setDialogOpen(true);
                      }
                    }
                  )()
                }
              >
                {t("save_changes")}
              </Button>
            </div>
          </div>
          <Dialog
            open={dialogOpen}
            onOpenChange={(open) => {
              setDialogOpen(open);
            }}
          >
            <DialogContent size="full">
              <FormBuilder />
            </DialogContent>
          </Dialog>
        </Form>
      </PageContent>

      <UnsavedChangesBlocker hasUnsavedChanges={hasUnsavedChanges && updateMutation.isIdle} />
    </Page>
  );
}
