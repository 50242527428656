import { trpc } from "@/lib/providers/trpc";
import { type Option, SelectMultipleBase, type ValueProps } from "./select-multiple";
import { useTenantIdSafe } from "@/hooks/useTenant";

export function SelectMultipleTenantUsers({
  values,
  onChange,
  disabled,
  className,
  collapseAt,
}: ValueProps) {
  const tenantId = useTenantIdSafe();
  const { data: users = [], isLoading } = trpc.tenantUsersList.useQuery({
    tenantId: tenantId,
    onlyIncludeWithActiveProfile: true,
  });

  const allOptions: Option[] = [
    ...users.map((user) => ({ id: user.userId, name: user.fullName! })),
    ...values
      .filter((val) => !users.some((user) => user.userId === val.id))
      .map((val) => ({ ...val, isNew: true })),
  ];

  return (
    <SelectMultipleBase
      collapseAt={collapseAt}
      className={className}
      disabled={disabled}
      isLoading={isLoading}
      options={allOptions}
      values={values}
      onChange={(selectedIds) => {
        const newValues = selectedIds
          .map((selected) => allOptions.find((opt) => opt.id === selected.id))
          .filter((v): v is NonNullable<typeof v> => v !== undefined);
        onChange(newValues);
      }}
    />
  );
}
